.ant-popover-inner{
    background:$grey_one;
    color:$light-light-4;
    border-radius:0.5rem;
}
.ant-popover-arrow-content::before{
    background: $grey_one;
}
.ant-popover-inner-content{
    color:$grey-four;
    padding-left:0.99rem;
    padding-right:0.99rem;
    padding-top:0.5rem;
    padding-bottom:0.5rem;
}
.ant-popover-content{
    border-radius:0.1rem;
    padding-top:0.99rem;
 
}