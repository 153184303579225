@import "src/styles/_variables.scss";
.ant-btn.ant-btn-loading{
    // width:6.125rem;
    // min-height: 2rem;
    justify-content: center;
    align-items: center;
    display: flex;
    // min-height:3rem;
    background-color:$primary-disabled;
    &.login-btn{
        height:2.5rem;
    }
}
.ant-btn.ant-btn-default[disabled]{
    border:0.1rem solid $light-light-5 !important;
    // color:$light-light-1 !important;
    opacity: 0.6;
    &:hover{
        background:none !important;
    }
}
.ant-btn-primary.table-title-btn{
    // padding:0.2rem;
}

.ant-btn-primary.table-download-btn{
    margin-left: -6.25rem;
}
@media screen and (max-width: 1408px) {
	.ant-btn-primary.table-download-btn{
		margin-left: -3.125rem !important;
	}
}
@media screen and (max-width: 1280px) {
	.ant-btn-primary.table-download-btn{
		margin-left: -2.5rem !important;
	}
}
@media screen and (max-width: 1214px) {
	.ant-btn-primary.table-download-btn{
		margin-left: -2.1rem !important;
	}
}
@media screen and (max-width: 1134px) {
	.ant-btn-primary.table-download-btn{
		margin-left: -1.1rem !important;
	}
}
@media screen and (max-width: 1090px) {
	.ant-btn-primary.table-download-btn{
		margin-left: -0.6rem !important;
	}
}

.ant-btn.ant-btn-primary.login-btn{

}
.ant-btn-link{
    border-bottom-width: 0.15rem !important; 
    border-bottom-color:$light-light-4 !important;
    color:$light-light-4 !important;
    background:none !important;
    border-left:none !important;
    border-right:none !important;
    border-top:none !important;
    border-radius:0 !important;
    padding:0 !important;
}

.ant-btn{
    box-shadow: none;
    padding:6px 11px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.ant-btn.ant-btn-primary{
    background-color: $secondary_second_color;
    color:$light-light-4;
    border:none;
    text-shadow:none;
    box-shadow: none;
    height:2rem;
    border-radius:6px;
    &:hover{
        background-color:$secondary_second_dark;
    }
    &:focus:not(:active){
        background-color: $secondary_second_color;
        color:$light-light-4;
        border:none;       
    }
    &:focus{
        background-color:$secondary_second_dark;
    }
    &[disabled]{
        background-color:$secondary_disabled;
        color:$light-light-4
    }
    &.login-btn{
        height:2.5rem;
    }
}

.ant-btn.ant-btn-default{
    background: transparent;
    border:0.1rem solid $light-light-4;
    color:$light-light-4;
    &:hover{
        background: $light-light-4;
        color:$secondary_second_color;
        border:0.1rem solid transparent;
    }
    &:focus:not(:active){
        background: none;
        border:0.1rem solid $light-light-4;
        color:$light-light-4;  
    }
    &:focus{
        background: $light-light-5;
        color:$secondary_second_color;
    }
    &[disabled]{
        background:none;
        border:0.1rem solid $primary-disabled;
        color:$light-light-4
    }
}


.ant-btn.ant-btn-text{
    background:none;
    color:$secondary_second_color;
    border:none;
    &:hover{
        background:none;
        color:$secondary_second_dark;
    }
    &:focus:not(:active){
        color: $secondary_second_color;
        background:none;
        border:none;       
    }
    &:focus{
        color:$secondary_second_dark;
        background:none;
    }
    &[disabled]{
        background:none;
        color:$primary-disabled-text;
    }

}

