.main-container-visitor{
    margin-left: 2rem;
    margin-right: 2rem;
    border-radius: 1rem;
    background-color: #fff;
    padding: 1.5rem 1.5rem 4rem 1.5rem;
    box-shadow: 8px 10px 35px #0000000A;
    z-index: 99;
    margin-top: -4rem;
}
@media screen and (max-height: 750px) and (max-width: 1380px) {
	.main-container-visitor {
	  margin-top: -2rem !important;
	}
}

@media screen and (max-height: 600px) and (max-width: 1780px) {
	.main-container-visitor {
	  margin-top: -2rem !important;
	}
}
@media screen and (max-width:600px){
    .main-container-visitor{
        margin-left: 1rem;
        margin-right: 1rem;
        padding: .5rem 0.5rem 2rem 0.5rem;
        margin-top:1.5rem;
    }
}

@media screen and (max-width: 550px) {
  .main-container-visitor{
    margin-top: 3rem !important;
  }
}

.mobile-responsive-table-section{
  padding-bottom: 1rem;
  margin-top: 0.3125rem;
}

.visitor-details-modal-mobile{
  left: -10px;
}

.mobile-responsive-section-log{
  padding-bottom: 1rem;
  margin-top: 0.3125rem;
}