.navAbsolute{
    position:absolute;
    top:-6rem;

}

.download-dropdown-mobile{
    font-size: 1.25rem;
    margin-left: 0.625rem;
}

.download-popover-mobile{
    .ant-popover-inner-content{
        margin-top: -0.4375rem;
    }
   .ant-popover-inner{
    background-color: white;
   }
   .ant-popover-content{
    padding-top: 1px;
   }
   .ant-popover-arrow{
    display: none;
   }
}