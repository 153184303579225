@import "src/styles/_variables.scss";

.reset-password {
    height: 100vh;
    width: 100vw;
    background-image: url('../../../resources/images/loginBackground.png');
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
}

.reset-password__container {
    padding: 4rem 3rem 4rem 3rem;
    margin: 1rem;
    background: transparent;
    filter: brightness(110%);
    backdrop-filter: blur(31px);
    -webkit-backdrop-filter: blur(31px);
    border-radius: 2.5625rem;
}
.reset-password__container-logo{
    text-align: center;
}
.reset-password__container-image{
    height: 4rem;
}
.reset-password-header {
    display: flex;
    flex-direction: column;
}

.reset-password__heading {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: bold;
    letter-spacing: 0;
    color: #393D46;
    opacity: 1;
}

.reset-password__heading-mobile {
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: bold;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
}

.reset-password__text {
    text-align: left;
    font-size: .875rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    color: #677781;
    opacity: 1;
}

.reset-password-form {
    margin-top: 3.125rem;
}

.reset-password-form__btn {
    font-size: 0.9375rem;
    line-height: 1.125rem;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    padding: 1.125rem;

}

label.floating-label-password {
    z-index: 9;
    font-size: 0.9rem;
    color: #677781;
    position: relative;
    pointer-events: none;
    left: 0;
    margin-top: -0.3125rem;
    transition: all 0.1s ease;

    &.error {
        color: $danger-primary;
    }
}

label.floating-label-otp {
    z-index: 9;
    font-size: 0.9rem;
    color: #677781;
    position: relative;
    pointer-events: none;
    left: 0;
    margin-top: -0.3125rem;
    transition: all 0.1s ease;

    &.error {
        color: $danger-primary;
    }
}

.verify-email-form__resend-otp {
    display: flex;
    justify-content: flex-end;
    font-size: 0.8125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    color: #8E8E8E;
    opacity: 1;
    margin-bottom: 0.9375rem;
    margin-top: -1.5625rem;
    margin-left: 0.3125rem;
}

.verify-email-form__resend-otp-mobile {
    display: flex;
    justify-content: flex-end;
    font-size: 0.8125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    color: #8E8E8E;
    opacity: 1;
    margin-bottom: 0.9375rem;
    margin-top: -1.25rem;
    margin-left: 0.3125rem;
}
