@import 'src/styles/_variables.scss';
.notice-row {
	position: relative;
	padding: 1rem 0 0.5rem 0.6rem;
	border-radius: 0.5625rem;
	height: auto;
	color: #ffffff;
	background: url('../../../resources/images/referFriendNew.png') center;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left 25px top 0;
}

.notice-row::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 0.5rem;
	background: linear-gradient(
		90deg,
		#0c68d9 0%,
		#0959ba 100%
	);
	opacity: 0.65;
}

h4.white_100 {
	color: $light-light-5;
	margin-bottom: 1.5rem;
}

@media screen and (max-width: 800px) {
	.notice-row {
		width: 100%;
	}
	h4.white_100{
		line-height:1.5;
	}
}
