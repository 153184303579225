@import 'variables';
@import './select.scss';
@import 'shared/components/Alert/index.scss';
@import 'shared/components/Popover/popover.scss';
@import 'shared/components/Table/table.scss';
@import 'shared/components/InputField/input.scss';
@import 'shared/components/Layout/scrollbar.scss';
@font-face {
	font-family: 'Arial';
	src: local('Arial'),
		url('../resources/fonts/Qcare-resident-icons.woff') format('woff');
}
body {
	z-index: 1;
}

.ant-drawer-body{
	&>ul.ant-menu-overflow::-webkit-scrollbar{
		display:none;
	  }
	  
}

div.top-navbar-fixed {
	background: $light-light-4;
	padding: 0.5rem 1rem 0.5rem 1rem;
}
.ant-btn.closableActionButton {
	&:hover {
		color: $alert-error;
	}
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background: #def3f0;
	// background: #EDF4FC;
}
.ant-switch-small {
	width: 32px;
}
span.avatar-actions {
	cursor: pointer;
}
/* Input Field */
.ant-input {
	border-radius: $input-border-radius;
	border-color: $input-border-color;
	&:focus,
	&:hover {
		border-color: #40a9ff;
		// border:none;
	}
	&:focus {
		// box-shadow: $input-border-box-shadow !important;
	}
}
p.MsoListParagraphCxSpMiddle {
	text-indent: 0 !important;
}
.ant-upload-list {
	display: flex;
}
span.ant-input-group-wrapper.antWithAddonBefore {
	& > span.ant-input-wrapper.ant-input-group {
		.ant-input {
			height: 2.5rem;
		}
	}
}

.ant-form-item.passwordChange {
	margin-bottom: 0;
}
.ant-select.profile-select-phone-number {
	& > .ant-select-selector {
		height: 20px;

		&:hover,
		:focus {
			border: none;
			box-shadow: none;
		}
	}
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input).ant-select-selector {
	border: none;
	box-shadow: none;
	outline: 0;
}

.ant-select.ant-select-in-form-item.profile-select-phone-number.ant-select-focused.ant-select-single.ant-select-show.arrow.ant-select-open.ant-select-show-search {
	box-shadow: none;
	border: none;
}
.ant-upload.ant-upload-select-picture-card {
	min-width: 104px;
	width: auto;
	min-height: 104px;
	height: auto;
	padding: 1rem;
	margin-right: 8px;
	margin-bottom: 8px;
	text-align: center;
	vertical-align: top;
	background-color: #fafafa;
	border: 1.5px dashed #65bcb0;
	border-radius: 2px;
	cursor: pointer;
	transition: border-color 0.3s;
}
.ant-select-single {
	height: 37px;
}
/* for the input phone type overrides */
div.PhoneInput {
	border: 0.1rem solid $light-grey;
	padding-left: 0.1rem;
	height: 2.3125rem;
	border-radius: 0.4rem;
}
section.ant-layout.site-layout.full-height {
	background: $light-light-4;
}
@media only screen and (max-width: 600px) {
	section.ant-layout.site-layout.full-height {
		background: $light-light-4;
	}
	main.ant-layout-content {
		margin-top: 0;
	}
}

//main container for all screens
.main-container {
	margin-left: 2rem;
	margin-right: 2rem;
	border-radius: 1rem;
	background-color: $light-light-4;
	padding: 1.5rem 1.5rem 3.5rem 1.5rem;
	box-shadow: 8px 10px 35px $box-shadow-dark;
	z-index: 99;
	margin-top: -4rem;
}

@media screen and (max-height: 750px) and (max-width: 1380px) {
	.main-container {
	  margin-top: -2rem;
	}
}

@media screen and (max-height: 600px) and (max-width: 1780px) {
	.main-container {
	  margin-top: -2rem;
	}
}


  

.ant-modal.modal-changed {
	.ant-modal-header {
		padding: 2rem 2rem 1rem 2rem;
		.ant-typography,
		.ant-modal-title {
			font-size: 20px;
		}
		.ant-space-item {
			line-height: 12px;
		}
	}
	.ant-modal-body {
		// padding: 0 0 0 10px !important;
	}
	.ant-typography,
	label {
		margin-bottom: 0.25rem;
		display: block;
	}
}


@media screen and (min-width:400px) {
	.ant-modal.modal-changed .ant-modal-body{
		padding:0.25rem 2rem 2rem 2rem !important;
	}
}

@media screen and (max-width: 600px) {
	.main-container {
		margin-left: 1rem;
		margin-right: 1rem;
		padding: 0.5rem 0.5rem 2rem 0.5rem;
		margin-top: 0;
	}
	.ant-menu-item {
		padding: 0;
	}
}

.ant-pagination.ant-pagination-mini .ant-pagination-item {
	min-width: 24px;
	height: 24px;
	margin: 0;
	line-height: 28px;
}

li.ant-pagination-item.ant-pagination-item-active {
	line-height: 30px;
	font-weight: bolder;
}
.ant-pagination-item.ant-pagination-item-1.ant-pagination-item-active {
	line-height: 30px;
}
.ant-table-title {
	padding: 16px 0;
}
input.PhoneInputInput {
	border: none;
}

.ant-collapse-ghost {
	background: $light-light-4;
}
/* Button */
.ant-layout-content {
	z-index: 0;
}
// .ant-select-focused:not(.ant-select-disablerd).ant-select:not(.ant-select-customize-input) .ant-select-selector{
//   border:none;
// }
.ant-btn {
	/* Primary button */
	background-color: $button-default-bg-color;
	color: $button-default-text-color;
	border-color: $button-default-border-color;
	border-radius: $button-default-border-radius;
	height: 2rem;
	// width:4.94rem;
	// &.ant-btn-primary {
	//   background-color: $button-primary-bg-color;
	//   color: $button-primary-text-color;
	//   border-color: $button-primary-border-color;
	//   border-radius: $button-primary-border-radius;
	// }
}

/* Checkbox */
.ant-checkbox-wrapper {
	&:hover {
		.ant-checkbox-inner {
			border-color: $checkbox-hover-border-color;
		}
	}
	.ant-checkbox-checked {
		.ant-checkbox-inner {
			background-color: $checkbox-bg-color;
			border-color: $checkbox-hover-border-color !important;
		}

		&::after {
			border-color: $checkbox-hover-border-color;
		}
	}
}

/* Radio Group */
.ant-radio {
	.ant-radio-inner {
		border-color: $radio-border-color !important;
	}

	&.ant-radio-checked {
		.ant-radio-inner {
			border-color: $radio-checked-border-color !important;
			&::after {
				background-color: $radio-checked-bg-color;
			}
		}
	}
}

/* Radio solid */
.ant-radio-group.ant-radio-group-solid {
	.ant-radio-button-wrapper {
		&:hover {
			color: $checkbox-hover-text-color;
		}
		&.ant-radio-button-wrapper-checked {
			background-color: $radio-checked-bg-color;
			border-color: $radio-checked-border-color !important;
			color: $checkbox-text-color;

			&::before {
				background-color: $radio-checked-bg-color !important;
			}
		}
	}
}

/* Select */
.ant-select {
	width: 100%;
	&:focus-visible {
		box-shadow: $select-focus-box-shadow !important;
	}
	.ant-select-selector {
		// border-color: $select-border-color !important;
		&:hover {
			border-color: #f5f5f500 !important;
		}
	}

	&.ant-select-open {
		.ant-select-selector {
			// box-shadow: $select-focus-box-shadow !important;
		}
	}
	&-arrow {
		color: black;
	}
}

.ant-select.ant-select-selector {
	&:hover {
		border: none;
		border-color: none;
	}
}

span.ant-input-group-addon {
	border-radius: 5px 0 0px 5px;
	background: $light-light-4;
}

.ant-pagination-item-active {
	border: none;
	line-height: 0;
}

.anticon.anticon-caret-up.ant-table-column-sorter-up {
	padding: 0;
}
.ant-tabs-tab {
	color: #8d8b8b;
}

.ant-tabs-tab:hover {
	color: $secondary_second_dark;
}
// .ant-select-multiple .ant-select-selection-item{
//   border-radius: 16px;
//     align-items: center;
//     justify-content: center;
//     /* width: 5.1rem; */
//     /* height: 2.3rem; */
//     background-color: #F7F7F7;
//     color: #8a8a8a;
// }
div.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
	// background:#49B1F3;
	background: $secondary_second_color;
}
.ant-typography.secondary__text {
	color: #b1b3b7;
	opacity: 1;
}
.ant-select-arrow .anticon {
	padding: 0;
}
.ant-typography.primary__text {
	color: #262727;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: $secondary_second_color;
}

.anticon.anticon-caret-down.ant-table-column-sorter-down {
	padding: 0;
}
// .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
//   width: 100%;
//   height: 26px;
//   padding: 0 11px;
//   border:none;
//   &:active,:focus{
//     border:none;
//   }
// }
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	width: 100%;
	// height: 26px;
	padding: 0 11px;
	border: none;
	align-items: center;
	justify-content: center;
}
.ant-select.profile-select.ant-select-single.ant-select-show-arrow {
	&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		width: 100%;
		height: 32px;
		padding: 0 11px;
		border: none;
		align-items: center;
		justify-content: center;
	}
}

div.ant-picker {
	border-radius: 0.3rem;
	padding-top: 0;
	padding-bottom: 0;
	height: 33px;
	background:$light-light-2;
	border:none;
}

ul.ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-right {
	position: absolute;
	z-index: 99;
	right: 0;
}

@media (max-width: 1280px) {
	ul.ant-pagination.ant-pagination-mini.ant-table-pagination.ant-table-pagination-right:first-of-type {
		display: none;
	}
}

.ant-form-item {
	margin-bottom: 1rem;
}
.ant-select-dropdown {
	box-shadow: none;
	border-radius: 0.6rem;
	border: 1px solid $grey-four;
}
.ant-input:focus {
	box-shadow: none;
}
.ant-tag {
	border-radius: 0.7rem;
}

.ant-switch-checked {
	background-color: #65bcb0;
}

.ant-layout-header{
	height: 7.0625rem;
}

.ant-menu-item:hover{
	background-color: #DEF3F0;
}

.anticon-calendar{
	color: $grey-ten;
	font-size: 15
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
	background-color: transparent;
}
.ant-pagination-item-active a{
	color: $secondary_second_color;
	
}

.ant-pagination-item a:hover{
	color: $secondary_second_color;
}

.ant-tabs-tab-btn:focus{
	color: #65bcb0;
}

.datepicker-mobile{
	.ant-picker-input > input{
		display: none;
	}
	padding-left: 0;
	padding-right: 0.3125rem;
}


// span.anticon-anticon-close{
//   background: white;
//   border-radius: 50%;
//   top: 0;
//   right: 0;
// 
