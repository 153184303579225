@import "src/styles/_variables.scss";

.image {
    min-height: 5rem;
    min-width: 4rem;
    border-radius: 0.3rem;
    overflow: hidden;
    position: relative;
    border: 2px solid #65bcb0;
    border-style: dotted;
    z-index: 1;

    &:hover {
        cursor: url("../../../resources/images/expand.svg"), auto;
    }

    object {
        position: relative;
        z-index: -1;
    }

    .background-image {
        width: 100%;
        height: 20vh;
        background-size: cover;
        background-position: center;
        position: relative;
        z-index: -1;
    }
}
.action{
    color:$light-light-4;
}
.bottomTag{
    position:absolute;
    bottom:1rem;
    left:0.4rem;
}
.text__for__documents{
    padding:1rem 0.3rem;
}
.upload-image-card-container {
    .expiry-date-wrapper {
        text-align: right;
    }
}
.container {
    padding: 0;
    border: none;
    border-radius: 6px;
    margin-top: 1rem;
}

.notUploadedImage{
    min-height:17rem;
    align-items: center;
    justify-content: center;
    display:flex;
    min-width:5rem;
    border:0.1rem dashed $secondary_second_color;
    padding:2rem;
    border-radius:0.3rem;
    cursor:pointer;
}

.greenText{
    color:$secondary_second_color !important;
}

.alignCenter{
    text-align:center !important;
}
.alignEnd{
    text-align: right !important;
}
span.ant-typography.upload-grey-text{
    color: #B1B3B7;
}