@import 'src/styles/_variables.scss';

span.ant-avatar.ant-avatar-circle {
	background-image: url('../../../resources/images/profileIcon.svg');
	background-size: 2.5rem;
}

.float {
	position: absolute;
	bottom: 0;
	right: 0;
	background: $secondary_second_color;
	border-radius: 50%;
	color: $light-light-4;
	border: .0625rem solid $light-light-4;
}
.float > .anticon {
	padding: 0.3rem;
}

.ant-avatar.ant-avatar-lg.ant-avatar-circle.profile {
	width: 5.625rem;
	height: 5.625rem;
	line-height: 2.5rem;
	border-radius: 50%;
}
.ant-avatar.ant-avatar-lg.ant-avatar-circle.profile {
	background-image: url('../../../resources/images/profileIcon.svg');
	background-size: 5.625rem 5.625rem;
}
