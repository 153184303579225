@import 'src/styles/_variables.scss';

.main-container-notices {
	margin-left: 2rem;
	margin-right: 2rem;
	border-radius: 1rem;
	background-color: $light-light-4;
	padding: 1.5rem 1.5rem 1.5rem 1.5rem;
	box-shadow: 8px 10px 35px #0000000a;
	z-index: 99;
	margin-top: -4rem;
}

.main-container-notices-mobile{
	margin-left: 1rem;
	margin-right: 1rem;
	border-radius: 1rem;
	background-color: $light-light-4;
	padding: 0.5rem 0.5rem 2rem 0.5rem;
	box-shadow: 8px 10px 35px #0000000a;
	z-index: 99;
	margin-top: -4rem;
}

.notice-container-mobile{
	img{
		width: 200px;
        height: 200px;
        object-fit: contain;

	}
}

.notice-container{
	img{
		width: 500px;
        height: 500px;
        object-fit: contain;

	}
}

.notice-header{
	font-size: 12px !important;
	word-wrap: break-word;
}
.notice-header-collapse{
	
		font-size: 11px !important;
		margin-bottom: 0.5rem !important;
		font-weight: 600 !important;
		line-height: 1.5 !important;
		color: $secondary_second_color !important;
		margin-top: 0.125rem;
		margin-left: -0.3125rem;
	
}
@media screen and (max-height: 750px) and (max-width: 1380px) {
	.main-container-notices {
	  margin-top: -2rem;
	}
}

@media screen and (max-height: 600px) and (max-width: 1780px) {
	.main-container-notices{
	  margin-top: -2rem ;
	}
}

@media screen and (max-width: 550px) {
	.main-container-notices-mobile{
	  margin-top: 1rem ;
	}
}
p.lineClamp {
	display: -webkit-inline-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-top: 1rem;
	color: #818181;
}

div.ant-picker.notices {
	background: $picker-background-grey;
}
.ant-collapse.notices > .ant-collapse-item > .ant-collapse-header {
	padding: 0.75rem 0;
}

.ant-collapse.notices
	> .ant-collapse-item.ant-collapse-no-arrow
	> .ant-collapse-header {
	padding-left: 0;
}
.ant-collapse-ghost > .ant-collapse-item {
	border-bottom: 0.1rem solid $light-grey !important;
}

.ant-collapse-ghost > .ant-collapse-item {
	&:last-child {
		border: none !important;
	}
}
.anticon.notices {
	background: $tertiary_second_lightest_color;
	padding: 0.4rem;
	align-items: center;
	justify-content: center;
	text-align: center;
	display: flex;
	border-radius: 0.25rem;
}

p > .viewMore {
	color: $secondary_second_color;
	margin-left: 0.4rem;
}
.inline {
	display: inline;
}
// .main-container{
//  margin-left: 2rem;
// margin-right: 2rem;
// border-radius: 1rem;
// background-color: $light-light-4;
// padding:1.5rem;
// box-shadow: 8px 10px 35px $box-shadow-dark;
// z-index:99;
// margin-top:-1rem
// }
.w-100 {
	width: '100%';
}

.mt-col {
	margin-top: 0.1875rem;
}

.primary__text_color {
	margin-bottom: 0.5em !important;
	font-weight: 600 !important;
	font-size: 1rem !important;
	line-height: 1.5 !important;
	color: $secondary_second_color !important;
	margin-top: 0.125rem;
	// margin-left: -5rem;
}
