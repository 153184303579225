
.login-form{
   padding:4rem 3rem 4rem 3rem;
   margin:1rem;
   border-radius:1.3rem;
//    background: #FFFFFF 0% 0% no-repeat padding-box;
// box-shadow: 0px 3px 25px #7090B079;
// border-radius: 41px;
// opacity: 1;
// backdrop-filter: blur(31px);

//    background: #FFFFFF 0% 0% no-repeat padding-box;
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    background:transparent;
    filter:brightness(110%);
    border-radius: 41px;
    opacity: 1;
//    background: #fff 0% 0% no-repeat padding-box;
//    box-shadow: 0px 3px 25px #7090B079;
   backdrop-filter: blur(31px);
   -webkit-backdrop-filter: blur(31px);
}
.ant-input-affix-wrapper-focused{
    box-shadow:none !important; 
}
div.margin__top__two{
    margin-top:2.4rem;
}
.input{
    // padding-top:0.1rem;
    // padding-bottom:0.1rem;
   
}
.loginPageRow{
    height:100vh;
    width:100vw;
    background-image:url('../../../resources/images/loginBackground.png');
    background-repeat:no-repeat;
    background-size: contain;
    overflow:hidden;
}

.sign-in-string{
    text-align: left;
font: normal normal bold 1.5rem Arial;
letter-spacing: 0;
color: #393D46;
opacity: 1;
font-weight: 900;

}

.sign-in-string-mobile{
    text-align: left;
    font: normal normal bold 1.5rem Arial;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
    font-weight: 900;
}
.login-form__forget-password{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: -20px;
    margin-bottom: 1.8rem;
    cursor: pointer;
}

.login-form__forget-password-text{
    text-align: left;
    font-size: 0.8125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    color: #65BCB0;
    opacity: 1;
}