@import 'src/styles/_variables.scss';
.main-container-community {
	margin-left: 2rem;
	margin-right: 2rem;
	border-radius: 1rem;
    background-color: #FFFFFF;
	margin-left: 2rem;
    margin-right: 2rem;
    border-radius: 1rem;
    background-color: #FFFFFF;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    box-shadow: 8px 10px 35px #0000000a;
    z-index: 99;
}
@media screen and (max-height: 750px) and (max-width: 1380px) {
	.main-container-community {
	  margin-top: -2rem !important;
	}
}

@media screen and (max-height: 600px) and (max-width: 1780px) {
	.main-container-community {
	  margin-top: -2rem !important;
	}
}

@media screen and (max-width: 550px) {
	.main-container-community {
		margin-left: 0;
		margin-right: 0;
		margin-top: 0.5rem !important;
	}
	.sad-animation-container {

			height:auto;
		}
}

@media screen and (min-width:600px){
	.main-container-community{
		margin-top:-4rem;
	}
	.sad-animation-container{
		height:80vh;
	}
}

.ant-divider.no-color-community {
	margin-top: 2rem;
}
.general_info_col_community_components {
	border-radius: 1rem;
	// background-color: #fff;
	padding: 2rem;
	box-shadow: 0.5rem 0.625rem 2.1875rem #0000000a;
	z-index: 99;
	// margin-top: -3rem;
	margin-right: 2rem;
}
.general_info_row_components {
	margin-left: 2rem;
	margin-right: 2rem;
	z-index: 99;
	margin-top: -1rem;
}
.ant-select.knowYourCommunitySelect {
	& > .ant-select-selector {
		& ~ .ant-select-selection-arrow {
			& > .ant-select-multiple.ant-select-selection-item {
				border-radius: 16px;
				align-items: center;
				justify-content: center;
				/* width: 5.1rem; */
				/* height: 2.3rem; */
				background-color: #f7f7f7;
				color: #8a8a8a;
			}
		}
	}
}
.knowYourCommunitySelect ~ .ant-select-selection-item {
	border-radius: 16px;
	align-items: center;
	justify-content: center;
	/* width: 5.1rem; */
	/* height: 2.3rem; */
	background-color: #f7f7f7;
	color: #8a8a8a;
}

img.ant-image-img.hobby-card-image {
	border-radius: 0.5rem;
	height: 6rem;
	width: 100%;
	object-fit: cover;
}
.anticon.anticon-close.ant-tag-close-icon {
	color: #403636;
}
.ant-tag.tagWithGreyText {
	background: #f7f7f7;
	// color:#403636;
	color: #403636;
	border: none;
	padding: 5px 14px;
	border-radius: 14px;
	& > .tag-has-color .anticon-close {
		color: black;
	}
	// border:none;
}
.ant-divider.no-color {
	border-top: 1px solid transparent;
	margin: 0 0;
}
.ant-tag.tagWithWhiteText {
	color: #ffffff;
	background: #aeb29b;
	border: none;
	padding: 5px 14px;
	border-radius: 14px;
}

.ant-tag.tagWithGreenText{
	color: $secondary_second_color;
	background: transparent;
	cursor: pointer;
	border: none;
	padding: 0.3125rem 0.875rem;
	border-radius: 0.875rem;
}

.ant-tag.booking-card-tag-success-community {
	padding-left: 12px;
	padding-right: 12px;
	padding-top: 4px;
	padding-bottom: 4px;
	border-radius: 1rem;
	border: none;
}

.ant-select.knowYourCommunitySelect {
	border-bottom: 1px solid #b1b3b7;
	&.ant-select-disabled.ant-select:not(.ant-select-customize-input)
		.ant-select-selector {
		background: #ffffff;
	}
}

.knowYourCommunitySelect.ant-select-multiple .ant-select-selection-item-remove {
	display: block;
}
.knowYourCommunitySelect {
	& ~ .ant-select-multiple > .ant-select-selection-item {
		border-radius: 1.5rem;
		padding-right: 1.3rem;
		padding-left: 1.3rem;
		padding-top: 0.7rem;
		padding-bottom: 0.7rem;
		align-items: center;
		justify-content: center;
		width: 7.1rem;
		height: 1.3rem;
		background-color: $tertiary_first_lighter_color;
		color: $grey_seven;
	}
}

div.interest-personal-card {
	border-radius: 0.6rem;
	background: $light-light-4;
	padding: 1rem;
	padding-right: 0;
	width: 100%;
	height: 100%;
	border: 1px solid #cac7c74b;
}

div.interest-personal-card-modal {
	padding: 2rem;
}
@media screen and (max-width:370px) {
	div.interest-personal-card-modal{
		padding: 1rem 0 0 0 ;
	}
}
img.ant-image-img.card-image {
	width: 100%;
	height: 7rem;
	border-radius: 0.5rem;
}
img.ant-img.preview {
	align-items: center;
	justify-content: center;
	display: flex;
}
img.ant-image-img.card-image-interest-card {
	max-width: 6rem;
	width: 6rem;
	height: 6rem;
	border-radius: 0.5rem;
	// min-width:3rem;
	// min-height:3rem;
	max-height: 6rem;
	align-items: center;
	justify-content: center;
	object-fit: center;
}
.general_info_col_components.rightCard {
	min-height: 20rem;
	max-height: 50rem;
	overflow: scroll;
}

.general_info_col_components {
	margin-top: -3rem;
	z-index: 99;
	border-radius: 1rem;
	background-color: #fff;

	// padding: 2rem;
	box-shadow: 0.5rem 0.625rem 2.1875rem #0000000a;
}
div.interest-row {
	border: 0.125rem dashed $secondary_second_color;
	border-radius: 1.2rem;
	background: $primary-background-light;
	padding: 3rem;
	color: $secondary_second_color;
	align-items: center;
	justify-content: center;
	text-align: center;
	cursor: pointer;
}
span.ant-typography.blueColor {
	color: $secondary_second_color;
}

.ant-typography.blackText {
	color: #262727;
}
h5.ant-typography.blackText {
	color: #262727;
}
.ant-typography.greyText {
	color: #cac7c7;
}

.sad-animation-text {
	span {
		margin-left: 1.0625rem;
		text-align: left;
		font: normal normal normal 1.25rem/1.75rem Arial;
		letter-spacing: 0;
		color: #939191;
		opacity: 1;
		width: 43%;
	}
}

.hobby-card-tag-space{
	margin-right: 0.5rem;
}

.share-contacts-content{
	margin-top: 0.625rem;
}

.card-image-interest-card2{
	max-width: 100%;
	max-height: 100%;
	width: 100%;
	height: 11rem;
	border-radius: 0.25rem;
	object-fit: cover;
}

.edit-interest-btn{
	cursor: pointer;
	color: $secondary_second_color;
	margin-left: 0.625rem;
	margin-top: 0.125rem;
	text-align: left;
	font-weight: bold;
}

.interest-card-image-container{
	margin-bottom: 0.75rem;
}

.sad-animation-container{
	width: 100%;
	text-align: center;
	//  margin: 27vh 10vh;
}

.interest-card-content-container {
	min-height: 120px;
}

.card-image-interest-card2-mobile{
	height: 6.875rem !important;
}

.hobby-card-image-mobile {
	height: 8rem !important;
	object-fit: cover;
	margin-left: 0.3125rem;
	margin-bottom: 0.3125rem;
}

.interest-personal-card-modal-mobile{
	padding: 1rem
}

.hobby-card-container{
    border: 1px solid #f0f0f0;
    padding: 1rem 0 1rem 0.4375rem;
    border-radius: 0.4rem;
}

.hobby-card-mobile__name{
	margin-left: -0.4375rem;
}

.hobby-card-mobile__interests{
	margin-left: 0.125rem;
}

.hobby-card-mobile-section{
	padding-left: .3125rem;
	padding-right: 0;
}