// @import "src/styles/_variables.scss";
// .main-container{ 
//     margin-left: 2rem;
//      margin-right: 2rem;
//       border-radius: 1rem;
//        background-color: $light-light-4;
//         padding: 1.5rem;
//          box-shadow: 8px 10px 35px $box-shadow-dark;
//           z-index: 99;
//            margin-top: -1rem 
// }
.mobile_container{
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    margin-top:2.5rem;
    background:white;
    padding:0.8rem;
}