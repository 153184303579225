div.logo{
    text-align: center;
    justify-content: center;


    // padding-top:2rem;
    // padding-bottom:1rem;
}
.logo-image{
    min-height: 2rem;
    max-height: 4rem;
}