@import 'src/styles/_variables.scss';

.navbar-item {
    float: right;
}

.ant-btn.ant-btn-default.circle-btn{
border:none;
&:hover,:active{
    background:none;
    border:none;
}
}
li.ant-menu-item.ant-menu-item-only-child.menu-item{
    padding:1rem;
    line-height:0;
    align-items: center;
    text-align: center;
    margin-bottom:0;
    &:hover,&:active,&:focus{
        background:$light-grey;
    }
}
.navbar-menu{
    z-index: 999;
    border-radius:0.7rem;
    padding:1rem;
    border:none;
    background:$light-light-4;
    position: absolute;
    right:1.2rem;
    top:5rem;
    border:0.1rem solid $light-light-5;
    &>.ant-menu-vertical{
        border:0;
    }
    &~.menu-item{
        &:hover{
            background:$light-light-5;
        }
    }
    &.navbar-user-menu {
        &>.ant-menu-vertical{
            border:0;
            padding: 0;
        }
    }
}

.navbar-menu__header{
    padding:0.6rem;
}
.greenText{
    color:$light-light-5;
}
.ant-layout-sider.ant-layout-sider-light.menu-sider{
    box-shadow: 8px 3px 30px #0000000B;
    z-index: 9;
}
div.ant-col.ant-col-3.circleIcon{
    display:flex;
    height:2rem;
    width:2rem;
    max-width:2rem;
    margin-top: 20px;
}
.circleIcon{
    background:#E8F2FF;
    border-radius:50%;
    // height:40px;
    // width:30px;
    align-items: center;
    justify-content: center;
    text-align: center;
   
}
div.ant-row.notification-item-row{
    padding:1rem;
    &span{
        line-height:1
    }
}

.ant-menu{
    padding-left: 0;
}

.ant-menu-item.ant-menu-item-only-child.menu-item-notifications{
    
    padding:0.3rem 0.4rem 0 1rem;
    width: 100%;
    height:auto;
    line-height:19px;
    &:hover{
        background:$light-light-3; 
    }
}
div.notice__icon{
height: 2.7rem;
 width: 2.7rem;
border-radius: 50%;
align-items: center;
text-align: center;
background: white;
justify-content: center;
display: flex ;
box-shadow: 0px 3px 15px #00000010;
cursor:pointer;
&:hover{
    border:1px solid #C7C6CB;
    box-shadow: none;
}
}
.ant-space.ant-space-horizontal.ant-space-align-center.navbar__menutop{
    text-align: left;
    align-items: center;
    justify-content: start;
    width: 100%; 
    height: 100% 
}
div.menu-top>.ant-row.ant-row-end.ant-row-middle{
  padding: 1.6rem
}
@media screen and (max-width:1500px){
    div.menu-top>.ant-row.ant-row-end.ant-row-middle{
        padding: 1.2rem
      }   
}
@media screen and (max-width:1200px){
    div.menu-top>.ant-row.ant-row-end.ant-row-middle{
        padding: .6rem
      }   
}

.menu-top{
    padding:0.2rem 0.5rem 0.5rem 0;
    position:relative;
//   position:absolute;
//   right:0;
}

.navbar-menu__notice-icon {
    margin-top: 0.5rem;

    svg {
        width: 16px;
        height: 20px;
    }
}

.navbar-menu__notice-text {
    font-weight: 600;
    font-size: .9375rem;
    text-shadow: 0.1px 0 #000000;
}

.navbar-menu__mark-text {
    margin-top: 0.5rem;
}

.notification__item-row-clock-icon {
    margin-top: 0.25rem;
}

.notification__item-row-date {
    font-size: 0.75rem;
}

.text--green {
    color: #65BCB0 !important;
    white-space: pre-wrap;
}

.text--black {
    color: #000000 !important;
    white-space: pre-wrap;
}


