@import 'src/styles/_variables.scss';
.header {
	width: '100%';
	height: 20vh;
	background-image: url('https://images.unsplash.com/photo-1520330979108-7d66e04b35e5?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb');
	background-size: cover;
	background-position: center;
}
.imageColumn {
	& > .ant-image {
		width: 100%;
	}
}
.bookingsMain {
	margin-top: -4rem;
	border-radius: 1rem;
	padding-left: 2rem;
	padding-right: 2rem;
	padding-bottom: 3rem;
	padding-top: 1rem;
	margin-left: 2rem;
	margin-right: 2rem;
	background: $light-light-4;
	box-shadow: .5rem .625rem 2.1875rem $box-shadow-dark;
}

@media screen and (max-height: 750px) and (max-width: 1380px) {
	.bookingsMain {
	  margin-top: -2rem;
	}
}

@media screen and (max-height: 600px) and (max-width: 1780px) {
	.bookingsMain {
	  margin-top: -2rem;
	}
}

@media screen and (max-width: 600px) {
	.bookingsMain {
		margin-top: 0;
		border-radius: 1rem;
		padding-left: 1rem;
		padding-right: 1rem;
		padding-bottom: 3rem;
		padding-top: 1rem;
		margin-left: 1rem;
		margin-right: 1rem;
		background: $light-light-4;
		box-shadow: .5rem .625rem 2.1875rem $box-shadow-dark;
	}
}

.ant-tag {
	border-radius: 0.7rem;
}
.ant-image-img.bookingCardImage {
	border-radius: 0.8rem;
	height: 8rem;
	// width: 90%;
	width: 8.8rem;
	max-width: 100%;
	margin-top: 0.4375rem;
}
.skeletonRow {
	margin-left: 1rem;
	width: 70vh;
}

.skeleteon-col-container {
	margin-left: 1rem;
	padding-top: 1rem;
	margin-bottom: 1rem;
	height: 100%;
	// width: 70vh;
}

.main-col-card {
	margin-top: 0.625rem;
}
