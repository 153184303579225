@import 'src/styles/_variables.scss';
.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {

	&::-webkit-scrollbar {
		display: none;
	}
}

.ant-menu-inline {
	.ant-menu-item {
		margin-bottom: 0.75rem !important;
		padding: 0.75rem !important;
		height: auto !important;
	}
	.ant-menu-item {
		.ant-menu-title-content {
			line-height: 0;
		}
	}
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected.mobile_bottom_nav {
	background: #fff;
}

.ant-menu-item-selected.mobile_bottom_nav a {
	color: #000;
}

div.bottom-navbar-fixed {
	position: fixed;
	bottom: 0;
	width: 100vw;
	height: 6.5rem;
	background: $light-light-4;
	padding: 0.5rem 0.3rem 0.4rem 0.3rem;
}
div.logo {
	text-align: center;
	// padding-top:2rem;
	// padding-bottom:1rem;
}
p.bottom {
	margin: 0;
	line-height: 0;
	padding-top: 0.8rem;
	font-weight: 400;
}
div.logo.logo-bottom {
	width: auto;
	color: #262727;
	&:hover {
		background: $primary-dark;
		color: white;
		p.bottom {
			color: #ffffff;
		}
	}
}
.d-flex {
	display: flex;
}
.ant-menu-item.ant-menu-item {
	width: calc(100%);
	border-radius: 0.5rem;
	text-align: left;
	padding-top: 2rem;
	//padding-bottom: 2rem;
	// padding-bottom:2rem;
	&::after {
		display: none;
	}
}


span.menuMobile{
	font-size:12px;
}
@media screen and (max-width: 350px) {
span.menuMobile{
	font-size:10px;
}
}


.ant-menu {
	// text-align: center;
	padding-left: 1rem;
	padding-right: 1rem;
	font-size: .9375rem;
	font-weight: 100;
	// max-height: calc(100vh - 200px);
	overflow-y: scroll;
}

@media screen and (max-width:600) {
	.ant-menu{
		max-height:calc(100vh - 250px);
	}
}

@media screen and (min-width:610) {
	.ant-menu{
		max-height: calc(100vh - 200px);
	}
}
.anticon {
	text-align: center;
	padding: 0.5rem;
}
.text-selected-menu {
	color: $secondary_second_color;
}
.text-unselected-menu {
	color: #5a5b5d;
	svg {
		color: #5a5b5d;
	}
}
.anticon.menu-selected-icon {
	color: $light-light-4;
	background: $secondary_second_color;
	// width: 85%;
	border-radius: 0.3125rem;
	animation: 0.1s ease-in-out;
}
.notice-icon-wrapper {
	.anticon.menu-icon {
		svg {
			width: 14px;
			transform: scale(1.35);
		}
	}
}

.anticon.menu-icon {
	color: $light-light-4;
	// background:$primary-color;
	border-radius: 0.3125rem;
	animation: 0.1s ease-in-out;
	svg {
		transform: scale(1.15);
	}
}

// .ant-menu-item{
//   &:active,&:focus,&:focus-visible{
//     &>.ant-menu-title-content{
//         &>.ant-row{
//             &>.ant-col{
//                 &>.anticon.menu-selected-icon{
//                         color:$light-light-4;
//                         background:$primary-color;
//                         border-radius:0.3125rem;
//                         animation:0.1s ease-in-out;
//                 }
//             }
//         }

//     }}

// }
.full-height {
	min-height: 100vh;
}

// .logo-image{
//     height:2rem;
// }

.menu-sider {
	overflow: auto;
	height: 100vh;
	width: 10rem;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;

	&::-webkit-scrollbar {
		display: none;
	}
}
.ant-layout.sider-layout {
	background: $light-light-4;
	border: none;
	box-shadow: 8px 3px 30px #0000000b;
}
li.ant-menu-item.mobile_bottom_nav {
	height: auto;
	width: 25%;
	padding-top: 0;
}
.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
	border: none;
	height: calc(100vh - 200px);
	box-shadow: 8px 3px 30px #0000000b;
}
header.ant-layout-header.menu-header {
	width: 100%;
	position: sticky;
	background: white;
	top: 0;
	z-index: 1;
	min-height: 100px;
	padding-top: 0.7rem;
}

footer.ant-layout-footer.logo-bottom {
	& > a {
		& > div.logo {
			padding-top: 0.4rem;
		}
	}
}
footer.ant-layout-footer.logo-bottom {
	width: 100%;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 275px;
	z-index: 1;
	padding: 0rem 0.5rem 0.2rem 0.5rem;
	justify-content: center;
	align-items: center;
	display: flex;
	box-shadow: $tertiary-shadow;
	&:hover {
		a {
			color: $light-light-4;
			& > div.logo {
				border: none;
			}
		}
		background: $primary-dark;
		color: $light-light-4;
	}
}
footer.ant-layout-footer {
	background: $light-light-4;
}
footer {
	a {
		padding-top: 0.4rem;
		color: $grey_one;
		&:hover {
			color: #000000;
		}
	}
}
footer.mobile__footer{

    position: sticky;

    bottom: 0;

    z-index:1;

    width: 100%;

    background: #fff;

    padding: 0.5rem 0 0 0;

}

.footer.ant-layout-footer.logo-bottom{
	padding: 0 !important;
}

.logo-bottom-mobile{
	margin-bottom: -0.425rem;
}