@import "src/styles/_variables.scss";

span.ant-upload-list-item-actions{
    top:0;
    color:$light-light-1;
}
.ant-upload-list-picture .anticon-close, .ant-upload-list-picture-card .anticon-close {
    background: $light-light-4;
    border-radius: 50%;
    top:10 !important;
    right: 10 !important;
    position:absolute;

}
.ant-upload-list-item .anticon-close{

    &:hover{
        color:$danger-primary !important;
    }
}
.ant-upload-list-picture-card .ant-upload-list-item-actions{
    top:30% !important;
    left:110% !important;
}
.fullWidth{
    width:100% !important;
    &>.ant-upload-list-picture-card{
        &>.ant-upload.ant-upload-select-picture-card{
            width:100% !important;
        }
    
    }
  
}