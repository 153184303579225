@import "src/styles/_variables.scss";
// .main-container{ 
//     margin-left: 2rem;
//      margin-right: 2rem;
//       border-radius: 1rem;
//        background-color: $light-light-4;
//         padding: 1.5rem;
//          box-shadow: 8px 10px 35px $box-shadow-dark;
//           z-index: 99;
//            margin-top: -1rem 
// }
div.alert-top{
    position:absolute;
    top:1rem;
    right:0.4rem;
    z-Index:99
}
// .mobile_container_electricity{
//     margin-top:3.5rem;
// }
.mobile_container_electricity{
    margin-top:3.5rem;
    padding: 0.5rem 0.5rem 2rem 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

@media screen and (max-width: 550px) {
    .mobile_container_electricity{
        margin-top: 4rem;
    }
}

h5.ant-typography.primary__text.no__margin__top{
    margin-top:0;
    font-weight:400;
}

.mobile_container{
    margin-left: 1.2rem;
    margin-right: 1.2rem;
    margin-top:2rem;
    background:white;
    padding:0.8rem;
}

.electricity-token-mobile-name{
    display: inline-flex;
}

.electricity-token-mobile-number{
    margin-top: 0.5rem;
}

.electricity-token-mobile-amount{
    margin-left: 0.125rem;
}