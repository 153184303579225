
/* Color palette */
/*primary colors */
$primary_main_color: #526CFF;
$primary_main_gradient_first_color:#06F5FF;
$primary_main_gradient_second_color:#D814F2;
$primary-lighter: #EDF6FF;
$primary-color: #49B1F3;
$primary-darkest: #245684;
$primary-dark: #263A80;
$primary-darker: #1E75AC;
$primary-subtle: #D2E8FC;
$primary-background-light:#F7FBFF;
$picker-background-grey:#F2F3F4;

/*primary colors end */

$alert-error:#C75E49;
$alert-success:#6AC17E;
/* secondary colors */
$secondary_first_color:#EF571B;
$secondary_first_lighter_color:#FDC6BC;
$secondary_second_color:#65BCB0;
$secondary_disabled: #99d6ce;
$secondary_second_dark: #3E8E83;
$secondary_second_lighter_color:#E1F7EC;
$secondary_third_color:#FD9F00;
$secodary_third_lighter_color:#FFEBC9;
$secondary_fourth-lighter_color:#E6DCFD;
$secondary_main_color:#FAFAFC;
$light-light-0: #B1B3B7;
$light-light-1: #A1A2A7;
$light-light-2: #F8F8F8;
$light-light-3: #FAFAFCF4;
$light-light-4: #FFFFFF;
$light-light-5:#F1F9FE;
/*secondary colors end */


/* Tertiary COLORS */
$tertiary_first_color:#8E8A8A;
$tertiary_first_lighter_color:#F7F7F7;
$tertiary_second_color:#AEB29B;
$tertiary_second_lighter_color:#EAEBE5;
$tertiary_second_lightest_color:#EBF4FD;
/* Tertiary colors end*/

/*Grey shades */
$grey_one:#262727;
$grey_two:#5E5F5F;
$grey_three:#A1A2A7;
$grey_four:#F8F8F8;
$grey_five:#2B2E40;
$grey_six:#BEBEBE;
$grey_seven:#8a8a8a;
$grey-eight:#B9C9D5;
$grey-nine: #A6B7C7;
$grey-ten: #5A5B5D;
$grey-eleven: #e8f5f3;


//danger
$danger-primary:#CF2655;
$primary-disabled:#96D2F8;
$primary-disabled-text:#D6E7FA;
// $primary-color: #0D494E;
// $secondary-color: #006FBA;
$light-white: #FFFFFF40;
$primary-light-color: #0D494E10;
$primary-black: #4B4B4B;
$dark-blue: #22509D;
$light-grey: #00000015;
$dark-grey: #8898AA;
$success-color: green;
$danger-color: #FE0000;
$warning-color: #F3B21B;
$body-bg: #F5F8FA;
$table-bg: #F7FAFC;
$input-bg: #F8FBFC;
$box-shadow-dark:#0000000A;
$box-shadow-darker:#0000000D;
/* Component colors */ 

/* Input Field */ 
$input-border-color: rgba(0, 0, 0, 0.164);
$input-border-color-hover: #006FBA;
$input-border-box-shadow: 0 0 0 2px rgba($color: #006FBA, $alpha: 0.2);
$input-border-radius: 4px;

/* Default Button */
$button-default-border-color: #00d404;
$button-default-bg-color: white;
$button-default-text-color: #00d404;
$button-default-border-radius: 4px;

/* Primary Button */
$button-primary-border-color: #009B02;
$button-primary-bg-color: #00d404;
$button-primary-text-color: white;
$button-primary-border-radius: 4px;

/* Checkbox */
$checkbox-bg-color: #00d404;
$checkbox-hover-border-color: #00d404; 
$checkbox-text-color: white;
$checkbox-hover-text-color: #00d404;

/* Radio */
$radio-border-color: rgb(202, 202, 202);
$radio-checked-border-color: #00d404;
$radio-checked-bg-color: #00d404;

/* Select */
$select-border-color: rgb(202, 202, 202);
$select-hover-border-color: #00d404;
$select-focus-box-shadow: 0 0 0 2px rgba($color: #00d404, $alpha: 0.2);

/* Shadows */
$primary-shadow: 0 8px 22px #0000001A;
$secondary-shadow: 0px 3px 6px #2C28281C;
$tertiary-shadow: -0.1875rem -0.1875rem 1.875rem #7090B01F;

/* Spacer variables */
$spacer-x : 1rem;
$spacer-y : 1rem;

