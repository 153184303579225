.ant-modal-header{
    border:none !important;
    border-radius:0.5rem !important;

}
.ant-modal-footer{
    border:none;
    text-align:left !important;

}
.ant-modal-body{
    padding:0 24px 24px 24px !important;
}
div.ant-modal{
    border-radius:0.5rem;
}
.ant-modal-content{
    border-radius:0.5rem !important;
}

.ant-btn.cancel__button{
   color:#CAC7C7 
}