@import "src/styles/_variables.scss";
.ant-table-title{
    position: relative;
    // top: -5rem;
    width:100%;
}
@media only screen and (max-width: 600px){
    .ant-table-title{
        top: -6.5rem; 
    }
}
.ant-table{
    // margin-top:2rem;
    position: relative;
}
.ant-table-thead > tr > th{
    background:$light-light-4;
    color:#B1B3B7;
    border:none;
}

.tableTitleFullWidth.table-home{
    & thead > tr > th{
// background:$light-light-2;
    }
}
div.ant-table.table-home.ant-table-thead > tr > th{
                            background:$light-light-2;
                        }
 
.ant-table-column-title{
    color:#B1B3B7;
}

th.ant-table-cell{
    color:#B1B3B7
}
.ant-table-tbody {
    &:last-child{
        border:none !important;
    }
}