@import "src/styles/variables";

.general_info_row_components{
    margin-left: 2rem;
    margin-right: 2rem;
    z-index: 99;
    margin-top: -1rem;
}
span.ant-typography.profile__secondary{
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 400;
    color:#B1B3B7;
    font-size:14px;
}
span.ant-typography.profile__primary{
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 400;
    color:#403636
}
.general_info_col_profile_components{
    border-radius: 1rem;
    background-color: #fff;
    padding: 2rem;
    box-shadow: 8px 10px 35px #0000000A;
    z-index:99;
}
.ant-divider.no-color{
    border-top:2px solid transparent;
    margin: 10px 0;
}
.ant-divider.no-color-top{
    margin:10px 0;
    border-top:2px solid transparent;
}
.ant-btn.ant-btn-text.font-bold{
    font-size:16px;
    font-weight:600;
}
.ant-typography.font-regular{
    font-size: 1.25rem;
    font-weight:400;
}
.ant-btn.ant-btn-text.font-bold{
    &>span{
        font-weight:bolder;
    }
   
}
.ant-tag.tagWithGreyTextForProfile{
 
    background:#F7F7F7;
    // color:#403636;
    color:#403636;
    border:none;
    padding: .0625rem .875rem;
    border-radius:.875rem;
    &>.tag-has-color .anticon-close{
        color:black 
    }
    // border:none;
}

.general_info_row_profile_components{
    margin-left: 2rem;
    margin-right: 2rem;
    z-index: 99;
    margin-top: -4rem;
}

// @media screen and (max-height:750px) {
// 	.general_info_row_profile_components{
// 		margin-top: -2rem !important;
// 	}
// }

@media screen and (max-height: 750px) and (max-width: 1380px) {
	.general_info_row_profile_components {
	  margin-top: -2rem;
	}
}

@media screen and (max-height: 600px) and (max-width: 1780px) {
	.general_info_row_profile_components {
	  margin-top: -2rem;
	}
}
@media screen and (max-width:600px) {
    .general_info_row_profile_components{
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        z-index: 99;
        margin-top: -2rem;
    }
}


.ant-select.profile-select.interests.ant-select-multiple.ant-select-show-search{
    &>div.ant-select-selector{
        padding:0.4rem;
    }
}
div.ant-select-selector.profile-select{
    border:0.1rem solid $light-grey;
    &~div.ant-select-selector{
        padding:0.4rem;
    }
}
div.ant-select-selector.profile-select.interests{
    border:0.1rem solid $light-grey;
    min-height:3rem;
}
div.PhoneInput.phoneInputVerify{
    border:none;
    padding:0.2rem;
    &:focus-visible,&:focus{
        border:none;
    }
}

.ant--select-selection-overflow-item{
    &>.ant-select-selection-item{
        color:black;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius:1rem;
    }
}



.booking-card-tag-success-profile{
    border:none;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius:1rem;
}
.ant-tag.ant-tag-has-color.booking-card-tag-success-profile.tagBlack{
    color:black;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius:1rem;
}
.ant.tagBlack{
color:black;
}
.ant-select.profile-select.ant-select-multiple.ant-select-show-search{
    border:0.1rem solid $light-grey;
    border-radius:0.2rem;
    &span.ant-select-multiple.ant-select-selection-item{
        padding:0.3rem;
    }
}

div.PhoneInput.phoneInput--focus{
    border:none;
}
.phoneInputGroup{
    border:1px solid $light-grey;
    border-radius:0.3rem;
    // padding:0.3rem;
    align-items: center;
    justify-content: center;
}
button.ant-btn.ant-btn-text.profile-verify{
font-size:0.7rem;
padding:0

}
.ant-select.profile-select.ant-select-single.ant-select-show-arrow{
    border:0.1rem solid $light-grey;
    border-radius:0.3rem;
}

// .ant-select.profile-select.ant-select-multiple.ant-select-show-search{
//     &~.ant-select-selector{
//         &~.ant-select-selection-overflow{
//             &.ant-select-selection-overflow-item{
//                 &.ant-select-selection-item{
//                     color:black;
// padding:0;
//                     border-radius:1rem;
//                 }
//             }
//         }
//     }
// }



.ant-select.ant-select-profile{
    &.ant-select-multiple.ant-select-selection-item{
        padding:0;
    }
}
.ant-select.profile-select-phone-number.ant-select-single.ant-select-show-arrow{
    border:none;
    border-radius:none;
    background:none;
}

.padding-block-seven{
    padding-block: .4375rem;
}