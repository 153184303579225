@import 'src/styles/_variables.scss';

.notice__icon-mobile {
    margin-top: -2rem;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    align-items: center;
    text-align: center;
    background: white;
    justify-content: center;
    display: flex;
    box-shadow: 0px 3px 15px #00000010;
    cursor: pointer;

    &:hover {
        border: 1px solid #C7C6CB;
        box-shadow: none;
    }

}

.navbar-menu-mobile {
    width: 22.5rem;
    right: 0.3rem;
    top: 4rem;
    z-index: 999;
    border-radius: 0.7rem;
    padding: 1rem;
    border: none;
    background: $light-light-4;
    position: absolute;
    border: 0.2rem solid $light-light-5;

    &>.ant-menu-vertical {
        border: 0;
    }

    &~.menu-item {
        &:hover {
            background: $light-light-5;
        }
    }

    &.navbar-user-menu {
        &>.ant-menu-vertical {
            border: 0;
            padding: 0;
        }
    }
}

@media screen and (max-width:415px){
    .navbar-menu-mobile{
        right: 1.2rem;
    }
}

@media screen and (max-width:395px){
    .navbar-menu-mobile{
        right: 0.5rem;
    }
}

