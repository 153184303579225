@import 'styles/_variables.scss';
.ant-alert-success{
    border:0.1rem solid $alert-success;

}
.ant-alert-info{
    border:0.1rem solid $light-light-4;
}
.ant-alert-warning{
border:0.1rem solid $secondary_third_color;

}
.ant-alert-error{
border:0.1rem solid $alert-error;
}
.ant-alert{
    padding-top:0.5rem;
    padding-bottom:0.5rem;
    border-radius:0.4rem; 
    background:$light-light-4;
    box-shadow: 0px 10px 25px #7090B01A;
}
.alert-absolute{
    position:absolute;
    top:1rem;
    right:1rem;
    z-index:99;
}
