@import 'src/styles/_variables.scss';

.ant-image-img.homeCardImage {
	border-radius: 0.5625rem;
	object-fit:cover;
	height: 95px;
	width: 111px;
	max-width: 100%;
}
.wallet-wrapper {
	margin-top: 2.75rem;
}
.amount-due-wrapper,
.wallet-amount-wrapper {
	margin-top: 0.75rem;
}
.current-token-wrapper {
	margin: 2.25rem 0 1rem 0;
}
.current-token-string {
	font: normal normal 0.8225rem Arial;
	font-weight: 100;
	color: #4a607c;
}
.current-token-value {
	font: normal normal normal 0.875rem Arial;
	font-weight: 900;
	color: #403636;
	opacity: 1;
}

.current-token-status {
	margin-top: -0.3125rem;
}

.electricity-token-price {
	margin-left: 0.625rem;
	font-size: 0.8125rem;
	color: #262727;
	opacity: 0.78;
}
.token-status-wrapper {
	> div > div {
		&:first-child {
			margin: 2rem 0 1.5rem 0;
		}
	}
}

.ant-btn.ant-btn-link.bottomText {
	align-items: flex-end;
}
.d-align-end {
	display: flex;
	align-items: end;
	justify-content: end;
	text-align: end;
	flex-direction: column;
}

.d-flex {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

h5.ant-typography.home__name {
	font-weight: bold;
	text-align: left;
	font-size: 1.0625rem;
	line-height: 1.3125rem;
	opacity: 1;
	color: #262727;
}
.ant-typography.secondary__text__home {
	color: #a6b7c7;
	font-size: 0.75rem;
	letter-spacing: 0;
	opacity: 0.71;
}
.ant-typography.primary__text__home {
	font-size: 0.75rem;

	color: #403636;
	opacity: 1;
}

@media only screen and (min-width: 1500px) {
	/* Commented for future use */
	// .whiteTitle,.secondaryText,.greyTitle{
	//     font-size:1.7rem
	// }
	// .ant-typography.ant-typography-secondary.greyTitle{
	//     font-size:1.4rem;
	// }
	// h5.ant-typography{
	//     font-size:20px;
	//     // line-height:0;
	// }
}

.ant-image-img.homeCardMobileImage {
	border-radius: 0.8rem;
	height: 2.625rem;
    max-width: 100%;
    width: 3.9375rem;
    object-fit: cover
	// width:100%;
}
.mobile-top-card-booking{
	margin-bottom: 0.625rem;
}
.antWithAddonBefore {
	& ~ .ant-input-group > .ant-input {
		border: none;
	}
}
span.ant-input-group-wrapper.antWithAddonBefore {
	& ~ span.ant-input-wrapper.ant-input-group {
		&input.ant-input {
			// border: 1px solid #d9d9d9;
			border-left: none;
		}
	}
}

.ant-select.homeSelect {
	border: 0.1rem solid $light-grey;
	border-radius: 0.4rem;
}
.mobile-top-card {
	padding: 1rem;
	background: #edf6ff;
	border-radius: 1rem;
	margin-bottom: 1rem;
}
.ant-tag.tag__home__welcome {
	background: #eaeaea;
	color: #5a5b5d;
	font-weight: bolder;
	border: none;
	padding: 0.4375rem 0.9375rem;
	border-radius: 0.8rem;
	font: normal normal bold 0.875rem Arial;
}
.h-100 {
	height: 100vh;
}
.h-95 {
	height: 90vh;
}
.fixedColumn {
	overflow-y: scroll;
	// padding-top: 2rem;
	// padding-left: 2rem;
	background: $light-light-4;
}
.fixedColumn::-webkit-scrollbar {
	display: none;
}

.fixed-column-mobile{
	overflow-y: scroll;
	background: $light-light-4;
	margin-top: 1rem;
}

.fixed-column-mobile::-webkit-scrollbar{
	display: none;
}

@media only screen and (min-width: 1800px), screen and (min-height: 740px) {
	.sider-bottom-image {
		position: absolute;
		bottom: 0;
	}
}
.whiteTitle {
	color: $light-light-4 !important;
}
h5.ant-typography.whiteTitle {
	color: $light-light-5;
	font-size: 1.375rem;
	font-weight: bolder;
	// font-size:1rem;
}
h5.ant-typography.blackTitle {
	color: $grey_one;
	font-size: 0.9375rem;
	// font-size:1rem;
}

.ant-typography.ant-typography-secondary.greyTitle {
	color: #ffffff;
	opacity: 0.72;
	// font-size:1rem;
}
.ant-typography.ant-typography-secondary.greyTitleAmount {
	color: #ffffff;
	opacity: 0.72;
	font-size: 1rem;
	&.nolineheight {
		line-height: 0;
	}
}
.titleAmount {
	font-size: 1.9rem;
	line-height: 0;
}
.title-secondary {
	font-size: 4rem;
	line-height: 0.3;
}
@media only screen and (max-width: 1025px) {
	.title-secondary {
		font-size: 2rem;
		line-height: 0.3;
	}
}
.ant-table-wrapper.tableTitleFullWidth {
	& > .ant-spin-nested-loading {
		& > .ant-spin-container {
			& > .ant-table {
				& > .ant-table-title {
					width: 100%;
					// background:$light-light-2;
				}
			}
		}
	}
}
div.right-sider {
	height: 100vh;
	background: $primary-darker;
}
h2.ant-typography {
	margin-bottom: 0;
}
div.right-part-home {
	height: 100vh;
	// width: 22rem;
	width: 24vw;
	position: fixed;
	right: 0;
	background: $primary-lighter;
}
.align-center {
	text-align: center;
}
.align-end {
	text-align: right;
}
.align-bottom {
	text-align: end;
	justify-content: end;
}
.cardWithGradient {
	border-radius: 1rem;
	opacity: 1;
	min-height: 10rem;
	background-position: center center;
	background-size: stretch;
	height: 100%;
	h3 {
		font-size: 1.75rem;
	}
}

@media screen and (max-width: 1300px) {
	.cardWithGradient {
		min-width: 10rem;
		background-size: 35rem;
	}
}

@media screen and (max-width: 1500px) {
	.cardWithGradient {
		min-width: 10rem;
		background-size: 35rem;
	}
}

@media screen and (max-width: 1800px) {
	.cardWithGradient {
		min-width: 10rem;
		background-size: 35rem;
	}
}
@media screen and (max-width: 2000px) {
	.cardWithGradient {
		min-width: 10rem;
		background-size: 35rem;
	}
}


div.ant-table-wrapper.tableTitleFullWidth.table-home {
	& > div.ant-spin-nested-loading {
		& > div.ant-spin-container {
			& > div.ant-table.ant-table-scroll-horizontal {
				background: transparent;
			}
		}
	}
}

div.ant-table-wrapper.tableTitleFullWidth.table-home {
	& > div.ant-spin-nested-loading {
		& > div.ant-spin-container {
			& > div.ant-table.ant-table-scroll-horizontal {
				& > div.ant-table-container {
					& > div.ant-table-content {
						& > .ant-table-thead > tr > th {
							// background:white;
						}
					}
				}
			}
		}
	}
}

.whiteTitleCheckedIn {
	font-size: 0.89rem !important;
	font-weight: bolder;
	margin-top: 0.1em;
	color: $light-light-5 !important;
}

h2.ant-typography.whiteTitle.titleAmount {
	line-height: 0.7;
	& > .ant-typography.secondaryText {
		font-size: 0.8rem;
	}
}
.h-100 {
	height: 100%;
}
.h-80 {
	height: 80%;
}
h2.ant-typography.whiteTitle {
	line-height: 0;
}
h3.ant-typography.whiteTitle {
	line-height: 1;
	margin-bottom: 0;
}
.p-2 {
	padding: 2rem;
}
.ant-col-14.align-middle {
	align-items: center;
	display: flex;
}
.p-2-sides {
	padding: 0 1rem 0 2rem;
}
.text {
	padding: 2rem;
}
.text-bottom-cards {
	padding: 0rem 2rem;
}
.ant-typography.font-weight-regular {
	font-weight: 300;
}
.buy-now-wrapper {
	margin-top: -0.1875rem;
	margin-left: -0.4375rem;
	cursor: pointer;
	span {
		font: normal normal bold 0.75rem/0.875rem Arial;
		color: $secondary_second_color;
		cursor: pointer;
	}
}

.electricity-redirection {
	cursor: pointer;
	margin-top: -0.2813rem;
	margin-left: 1.0625rem;
}
.ant-typography.ticket-open {
	font-size: 1.75rem;
	font-weight: 400;
	color: #403636;
	opacity: 1;
	margin-top: 0.5625rem;
	margin-bottom: 0;
}

.ant-typography.ticket-closed {
	font-size: 1.75rem;
	font-weight: 400;
	color: #3daf98;
	margin-top: 0.8125rem;
	margin-bottom: 0;
}

.ticket-closed-string {
	text-align: left;
	font: normal normal normal 0.875rem/1.5625rem Arial;
	letter-spacing: 0;
	color: #262727;
	opacity: 0.35;
}

.maintenanceAdd {
	margin-left: -0.4375rem;
	cursor: pointer;
}
.maintenance-view-btn {
	cursor: pointer;
}
.ant-typography.font-weight-lighter {
	font-weight: 100;
}

.mpesa-col {
	margin-top: 0.875rem;
}
.mpesa-string {
	margin-right: 0.25rem;
	text-align: left;
	font: normal normal normal 0.875rem/0.9375rem Arial;
	letter-spacing: 0;
	color: #4a607c;
	opacity: 1;
}
.mpesa-val {
	text-align: left;
	font: normal normal normal 0.875rem/0.9375rem Arial;
	letter-spacing: 0;
	color: #403636;
	opacity: 1;
}
.ant-typography.discount-text {
	text-align: left;
	// padding-left: 10px;
	font: normal normal normal 0.8125rem/0.9375rem Arial;
	color: #403636;
	opacity: 1;
	margin-top: -1.1875rem;
}
span.discount-text {
	// margin-top: 7px;
	color: #403636;
	display: flex;
	justify-content: flex-end;
}
.text_first {
	padding: 2rem;
}

.depositGap {
	gap: 0.1875rem;
}

.booking__id__text {
	color: $grey-nine;
	text-align: left;
	font: normal normal normal 0.75rem/0.9375rem Arial;
	opacity: 1;
}

.sider-image {
	object-fit: cover;
	width: 100%;
	max-height: 100%;
	height: 13.625rem;
	position: relative;
}

.view-all-container {
	span {
		padding-left: 2.6875rem;
		text-align: left;
		font: normal normal bold 0.9375rem/1rem Arial;

		opacity: 1;
	}
}

.no-token-string-col {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1.25rem;
	font: normal normal normal 0.875rem/1.5625rem Arial;
	letter-spacing: 0;
	color: #262727;
	opacity: 0.35;
}
.electricity-amount-unit {
	margin-left: 0.3125rem;
	margin-top: 0.8125rem;
}
@media only screen and (min-width: 1401px) {
	.text_first {
		padding: 2rem;
	}
}

@media only screen and (max-width: 600px) {
	.text_first {
		padding: 1rem 0 1rem 1rem;
	}
}
.ant-typography.secondaryText {
	color: $light-light-3;
	opacity: 75%;
}

.skill-share-btn {
	background-color: transparent !important;
	border: 0.0625rem solid $secondary_second_color !important;
	color: $secondary_second_color !important;
	cursor: pointer;
	&:hover {
		background-color: $secondary_second_color !important;
		color: #ffffff !important;
	}
	margin-left: -0.5625rem;
}

.residence-refer-container {
	margin-top: 30px;
	gap: 10px;
}
.residence-image-container {
	// margin-right: 0.625rem;

}
.residentpane-room-details {
	gap: 0.3125rem;
}
.refer-friend-content {
	padding-left: 0.3125rem;
	padding-bottom: 1.25rem;
}

.welcome__container {
	padding-left: 32px !important;
	padding-right: 32px !important;
	padding-top: 32px !important;
}


div.justify-center{
display: flex;
justify-content: center;
align-items: middle;
height:100%;
}
.welcome__container-mobile {
	padding-left: 1rem !important;
	padding-right: 1rem !important;
	padding-top: 0 !important;
}

.card__row-mobile{
	padding-top: 0;
}

.card__row{
	padding-top: 2rem;
}

.electricity-token-first-row {
	display: flex;
	justify-content: space-between;
}


@media (max-width: 1400px) {
	.text_first {
		padding-left: 1.1rem;
		padding-right: 0;
	}

	.maintenance-view-btn {
		margin-left: -0.5rem;
	}
}

@media screen and (max-width: 1380px) {
	.booking-status-container{
		margin-left: -0.625rem !important;
	}
	
  }

  @media screen and (max-width: 1280px) {
	.booking-status-container{
		margin-left: -0.875rem !important;
	}
  }

.card__btn {
	display: flex;
	justify-content: flex-end;
	margin-top: -2.6875rem;
	margin-left: -1rem;
}

.current-booking-modal{
	.ant-modal-header .ant-modal-title{
		margin-left: -0.5rem;
	}
}

.amount-due-modal{
	.ant-modal-header .ant-modal-title{
		margin-left: -0.5rem;
	}
	.ant-modal-close{
		margin-left: 0.3125rem;
	}
}

.refund-modal {
	.ant-modal-header .ant-modal-title {
		margin-left: -0.5rem;
	}
}

.maintenance-ticket-modal {
	.ant-modal-header .ant-modal-title {
		margin-left: -0.125rem;
	}
}

.plus-btn{
	margin-right: 0.3125rem;
}

.electricity-token-card{
	padding: 1.1rem;

	.electricity-token-card__name{
		margin-right: 0.3125rem;
	}
}

.electricity-token-modal{
	.ant-modal-header .ant-modal-title{
		margin-left: -0.5rem;
	}

	.electricity-token-modal__building-info{
		width: 100%;
		margin-bottom: 0.625rem;
	}

	.electricity-token-modal__meter-info{
		margin-bottom: 0.625rem;
	}
}


@media (max-height: 740px) {
	.sider-bottom-image{
		display: none;
	}
}

.electricity-token-name{
	margin-right: 0.375rem;
}

.maintenance-card-name{
	margin-right: 0.375rem;
}

.maintenance-card-add-btn{
	margin-top: -0.0625rem;
}

.electricity-token-add-btn{
	margin-top: -0.0625rem;
}