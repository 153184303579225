@import "variables";
@import "helpers";
@import "@antOverrides";

/* General component styles */
body {
  background-color: #FFFFFF;
  color: $primary-black;
}

body, h1, h2, h3, h4, h5, button, input {
  font-family: 'Arial', sans-serif !important;
}
