.forgot-password {
    height: 100vh;
    width: 100vw;
    background-image: url('../../../resources/images/loginBackground.png');
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
}

.forgot-password__container {
    padding: 4rem 3rem 4rem 3rem;
    margin: 1rem;
    background: transparent;
    filter: brightness(110%);
    backdrop-filter: blur(31px);
    -webkit-backdrop-filter: blur(31px);
    border-radius: 2.5625rem;
}

.forgot-password__container-logo{
    text-align: center;
}

.forgot-password__container-image{
    height: 4rem;
}

.forgot-password-header {
    display: flex;
    flex-direction: column;
}

.forgot-password__heading {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.75rem;
    letter-spacing: 0;
    color: #393D46;
    opacity: 1;
}

.forgot-password__heading-mobile {
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.75rem;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
}

.forgot-password__text {
    text-align: left;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    color: #677781;
    opacity: 1;
}

.forgot-password__text-mobile {
    text-align: left;
    font-size: 0.8125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    text-align: center;
    margin-top: 0.3125rem;
}

.forgot-password-form {
    margin-top: 3.125rem;
}

.forgot-password-form__otp-btn {
    font-size: 0.9375rem;
    line-height: 1.125rem;
    letter-spacing: 0;
    color: #FFFFFF;
    opacity: 1;
    padding: 1.125rem;
}

.forgot-password-form__back-btn {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: bold;
    letter-spacing: 0;
    color: #65BCB0;
    opacity: 1;
    margin-top: 0.9375rem;
}