.ant-select-multiple .ant-select-selection-item{

    border-radius:1.5rem;
    // padding-right:1.3rem;
    // padding-left:1.3rem;
    // padding-top:0.7rem;
    // padding-bottom:0.7rem;
    align-items: center;
    justify-content: center;
    width:5.1rem;
    height:2.3rem;
    background-color: $tertiary_first_lighter_color;
    color:$grey_seven
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: $light-grey;
   
}
.ant-select{
    // padding-top:0.8rem;
    // padding-bottom:0.8rem;
    // padding-left:0.5rem;
    // border-radius:0.8rem;
    border:none;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)::after{
    display: none;
}
.ant-select-multiple .ant-select-selection-item-remove{
    display:none ;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    // background:#FAFAFCF4 !important;
    border:none;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    background:#FAFAFCF4 !important;
}
.ant-select-item-option{
    // padding:1rem;
    // padding-top:1rem;
    // padding-bottom:1.2rem;
    // padding-left:2rem;
    margin-left:2%;
    margin-right:2%;
    margin-top:0.4rem;
    border-radius:0.4rem;
    align-items: left;
    text-align: left;
    border:none;
    &::after{
        display:none;
    }

}


element.style {
    user-select: none;
    display: none;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state{
    display:none;
}