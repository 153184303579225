@import "src/styles/_variables.scss";
.backgroundImage{
    width:100%;
height:20vh;
background-size:cover;
background-position: center;
   z-index: -1;
}
.MainScreen{
    margin-left: 2rem;
    margin-right: 2rem;
    border-radius: 1rem;
    background-color: $light-light-4;
    padding:1.5rem;
    box-shadow: 8px 10px 35px $box-shadow-dark;
    z-index:99;
    margin-top:-1rem 
}