.ant-tag.tag--green {
	color: #65bcb0;
	// border:none;
}
// .ant-tag.tagGreenHome{
//     color:#65BCB0;
// }
.ant-tag {
	margin: 0;
	padding: 0.3125rem 0.625rem;
}
.ant-tag.booking-card-tag-success {
	border: 0.0625rem solid transparent;
	// padding-left: 9px;
	// padding-right: 9px;
	// padding-top: 5px;
	padding-bottom: 0.3125rem;
	border-radius: 1rem;
}
.ant-tag.booking-card-tag-success-home {
	// border:1px solid ;
	// padding-left: 18px;
	// padding-right: 18px;
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	border-radius: 1rem;
	border: none;
}
.ant-tag.tag--green {
	color: #65bcb0;
	// border:none;
}

// .ant-tag.tagBlueHome{
//     color:#49B1F3;
//     }

.ant-tag.tag--orange {
	background: #ffebc9;
	color: #fd9f00;
}

// .ant-tag.tagOrangeHome{
//     background:#FFEBC9;
//     color:#FD9F00
// }

.ant-tag.tag--red {
	border: 0.0625rem solid transparent;
	background: #fbd4c4;
	color: #ef571b;
}
// .ant-tag.tagRedHome{

//     background:#FBD4C4;
//     color:#EF571B;
// }
// span.ant-tag.ant-tag-has-color.booking-card-tag-success.tag--green{
//     color:#65BCB0
// }
