@import 'src/styles/_variables.scss';
// .main-container{
//     margin-left: 2rem;
//      margin-right: 2rem;
//       border-radius: 1rem;
//        background-color: $light-light-4;
//         padding: 1.5rem 1.5rem 3.5rem 1.5rem;
//          box-shadow: 8px 10px 35px $box-shadow-dark;
//           z-index: 99;
//            margin-top: -1rem
// }

// @media screen  and (max-width:600px){
//     .main-container{
//         margin-left:1rem;
//         margin-right:1rem;
//         padding: 3.5rem 0.5rem 2rem 0.5rem ;
//     }
// }
p.p-noBottomPadding {
	margin-bottom: 0;
}
@media screen and (max-width:600px){
.main-container.maintenance{
	margin-top:3.5rem;
}
}

@media screen and (max-width:550px){
	.main-container.maintenance{
		margin-top:4.5rem;
	}

}
.ticket-modal-title {
	font-size: 20px;
}

.ant-modal.modal-changed.maintenance-ticket-modal {
	.ant-modal-header {
		padding: 2rem;
	}
	.ant-modal-body {
		padding: 0 2rem 2rem 2rem !important;
		p {
			color: #b1b3b7;
			margin-bottom: 0.5rem;
		}
	}
}
.ant-modal.modal-changed.raise-ticket-modal {
	.ant-typography.secondary__text {
		margin-bottom: 0.25rem;
		display: block;
	}
	.ant-select-single,
	textarea {
		margin-bottom: 0.5rem;
	}
}
div.ant-select-selector.profile-select {
	border: 0.1rem solid $light-grey;
}

.maintenance-heading {
	margin-top: 0.125rem;
	h2 {
		text-align: left;
		font: normal normal normal 1.25rem/1.4375rem Arial;
		color: #2c2c2c;
		opacity: 1;
	}
}

.ticket-modal-title-mobile{
	font-size: .9375rem;
}