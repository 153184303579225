@import 'src/styles/_variables.scss';
.ant-tag {
	border-radius: 0.7rem;
}

.bookingsCard {
	margin-top: 0.625rem;
	.tags-wrapper {
		margin-top: 0.5rem;
	}
}

.ant-image-img.bookingCardImage {
	border-radius: 0.8rem;
	height: 8rem;
	width: 90%;
	object-fit: fill;
}

p.grey-text {
	color: #403636;
	opacity: 0.71;
}
span.ant-typography.grey-text {
	color: #403636;
	opacity: 0.71;
}
h4.black__text__cards {
	color: #262727;
}
span.ant-typography.black__text__cards.title__card__text {
	font-size: 1.25rem;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 600;
	color: $grey_one;
}
h2.black__text__cards {
	color: $grey_one;
}

.card-booking-id {
	text-align: left;
	font: normal normal normal 0.875rem/0.875rem Arial;
	margin-top: 0.5625rem;
	margin-left: 0.5rem;
	color: $grey_one;
	opacity: 1;
}

.card-right-rtwo {
	margin-top: 0.625rem;
	span {
		margin-right: 0.3125rem;
	}
}

.card-right-rthree {
	margin-top: 2.375rem;
	.monthly-rent-logo {
		margin-top: 0.3125rem;
		background: $grey-eleven 0% 0% no-repeat padding-box;
		border-radius: 50%;
		height: 1.5rem;
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 1;
	}
	.monthly-rent-string {
		text-align: left;
		font: normal normal normal 0.875rem/0.9375rem Arial;
		color: #a6b7c7;
		opacity: 1;
		margin-left: 0.3125rem;
	}
	.monthly-rent-unit {
		text-align: left;
		font: normal normal normal 0.875rem/0.875rem Arial;
		letter-spacing: 0;
		color: $grey_one;
		opacity: 1;
		margin-left: 0.5625rem;
	}
}

.card-right-rthree-mobile {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	
	.monthly-rent-logo-mobile {
		margin-top: 0.3125rem;
		background: $grey-eleven 0% 0% no-repeat padding-box;
		border-radius: 50%;
		height: 1.5rem;
		width: 1.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		opacity: 1;
	}
	.monthly-rent-string-mobile {
		text-align: left;
		font: normal normal normal 0.875rem/0.9375rem Arial;
		color: #a6b7c7;
		opacity: 1;
		margin-left: 0.3125rem;
	}
	.monthly-rent-unit-mobile {
		text-align: left;
		font: normal normal normal 0.875rem/0.875rem Arial;
		letter-spacing: 0;
		color: $grey_one;
		opacity: 1;
		margin-left: 0.5625rem;
	}
}

@media screen and (max-width:550px) {
	.card-right-rthree{
		margin-top:.375rem;
	}
	.card-booking-id{
		margin-left:0;
	}
	.booking-status{
		margin-top:0.5rem
	}
}

.w-100{
	width: 100%;
}

.mt-six{
	margin-top: .375rem;
}
.mr-10{
	margin-right:10px;
}

.bookings-card-mobile{
	margin-top: 0.625rem;
	margin-left: 0 !important;
	.tags-wrapper {
		margin-top: 0.5rem;
	}
}

.card-booking-id-mobile{
	text-align: left;
	font: normal normal normal 0.875rem/0.875rem Arial;
	margin-top: 0.4625rem;
	margin-bottom: 0.625rem;
	color: $grey_one;
	opacity: 1;
}

.booking-status-mobile{
	margin: 0px 0 0.625rem 0;
}

.card-right-rtwo-mobile{
	font-size: 0.8rem;
	margin-left: 0.125rem;
	margin-bottom: 0.625rem;
}

.booking-card-mobile__image{
	margin-left: 0.125rem;
}

.booking-card-mobile__details{
	margin-left: 0.125rem;
}

.booking-card-divider{
	margin-bottom: 0 !important;
}

.agreement-card-btn{
	margin-top: .9375rem;
}

.argreement-card-mobile{
	margin-left: 0 !important;
}

.booking-card-mobile__info{
	display: flex;
	flex-direction: column;
}