
@import "src/styles/_variables.scss";
input.ant-input{
    padding:0.7rem;
    border:1.5px solid $grey_eight;
    border-radius:6px;
    height:3rem;
    //width:20rem;
   &:hover{
    // border:0.1rem solid  #006FBA;;
  }
  &.ant-input-status-error{
    border:0.1rem solid $danger-primary !important;
  }
}
.inputWithIcons{
    background:$light-light-2;
    border:none;
}
.ant-input-affix-wrapper.inputWithIconsForCommunity{
    background:$light-light-2;
    border-radius: 0.4rem;
    padding: 0 0.3rem 0 0.7rem;
    border: none;
    height: 1.9rem;

}

.ant-input-affix-wrapper.antWithEmail {
    height: 38px;
    border: 0.1rem solid #00000015;
    &:hover {
        border: 0.1rem solid #00000015;
    }
    .ant-select-single {
        height: auto;
        .ant-select-selector {
            padding: 0 11px 0 6px;
        }
        &.ant-select-focused {
            .ant-select-selector {
                border: none;
                box-shadow: none;
            }
        }
        .ant-select-selection-search {
            left: 6px;
            input {
                height: 20px;
            }
        }
    }
}

.ant-input{
    &.antWithEmail{
        padding:0 0.3rem 0 0.7rem;
        border:0.1rem solid $light-grey;
        height: 38px;
        border-radius:5px; 
        &:focus,:active,:hover{
            // border:0.1rem solid $light-grey;
        } 
    }
}
.ant-select.input-with-prefix-select.ant-select-single.ant-select-show-arrow{
    &:focus{
        border:none;
    }
}
.ant-input-affix-wrapper.ant-input-password{
    padding: 0.4rem 0.7rem 0.4rem 0.7rem;
    border: 1.5px solid #B9C9D5;
    border-radius: 6px;
    height: 3rem;
}

.ant-input-affix-wrapper.antWithEmail{
    &>.ant-input{
        background:none;
    }
}

.ant-input-affix-wrapper {
    border-radius:0.4rem;
    padding:0 0.3rem 0 0.7rem;
    border:none;
    height:1.9rem;
    &>.ant-input{
        height: auto;
        background:transparent;
        // background:$light-light-2;
        &:hover {
            border:none !important;
        }
    }
   
}

.ant-input-affix-wrapper.dropdownInput{
    border:0.1rem solid $light-grey !important;
    box-shadow: none !important;
    &>.ant-input{
        background:none;
        &:focus,:hover{
            border:none;
            box-shadow: none;
        }
    }
    &>.ant-input-prefix{
        &>.ant-select{
            &>.ant-selector{
                box-shadow: none;
                border:none;
                &:focus,:active,:hover{
                    box-shadow: none;
                    border:none;
                }
            }
        }
    }
}
.ant-input[type='date']{
    height:2.1rem;
    border:none;
}
.ant-input.ant-input-disabled{
    height:2rem;
    background:#FAFAFCF4;
    border:none;
    &.antWithEmail {
        height: 38px;
    }
}

.ant-input[disabled]{
    background:$light-light-3;
}
.input-group>.ant-input-affix-wrapper.ant-input-password {
    .floating-label {
        z-index: 9;
		font-size: 0.9rem;
		color:#677781;
		position: absolute;
		pointer-events: none;
 
		left: 0;
        top:-1.8rem;
		transition: all 0.1s ease;
        &.error{
            color:$danger-primary;
        }
	}
}
.input-group{
	position: relative;
	margin-top: 1.1rem;
	margin-bottom: 1.8rem;

	.floating-label {
        z-index: 9;
		font-size: 0.9rem;
		color:#677781;
		position: absolute;
		pointer-events: none;
 
		left: 0;
        top:-1.4rem;
		transition: all 0.1s ease;
        &.error{
            color:$danger-primary;
        }
	}
}


input.ant-input.ant-input-status-error{
    &~.floating-label{
        top:-1.5rem;
        left:0;
    }
}



input.ant-input{
    // &:focus{
    //     &~.floating-label{
    //         top:-1.5rem;
    //         left:0;
    //     }
    // }
    // &:not([value='']){
    //     &~.floating-label{
    //         top:-1.5rem;
    //         left:0;
    //         }
    // }
}
// input.ant-input[type='password']{

//         &~.floating-label.error{
//         top:-1.5rem;
//         left:0;
//         }
//         &~.floating-label{
//             top:1rem;
   
//         }
//         &:focus{
//             &~.floating-label{
//                 top:-1.5rem;
//                 left:0;
//                 }  
//         }
//         &:not(:invalid){
//             &[value=""]{
//                 &~.floating-label{
//                     top:0.8rem;
//                 }
//             }
//             &:not([value=""]){
//                 &~.floating-label{
//                     top:-1.5rem;
//                     left:0;
//                     }  
//             }
           
//         }
       
//     }


    // input.ant-input:not(:invalid){
    //     &~.floating-label{
    //         top:-1.5rem;
    //         left:0
    //     }
    // }

    // input.ant-input:not([type='password'])[value='']{
    //     &:focus{
    //         & ~.floating-label{
    //             top:-1.5rem;
    //             left:0;
    //         }
    //     }
       
    // }
input.ant-input{
    box-shadow:none;
    &~.floating-label{
        // top:-1.5rem;
        left:0;
        z-index: 9;
		font-size: 0.9rem;
		color:#677781;
		position: absolute;
		pointer-events: none;
 
		left: 0;
        top:-1.5rem;
		transition: all 0.1s ease;
        &.error{
            color:$danger-primary;
        }
    }
}
// input.ant-input:not([type='password']):not(:invalid){
//     &:not([value='']){
//         & ~.floating-label{
//             top:-1.5rem;
//             left:0;
//         }  
//     }
// }
//     input.ant-input:invalid{
//         &:focus{
//             & ~.floating-label{
//                 top:-1.5rem;
//                 left:0;
//             }
//         }
//         & ~.floating-label{
//             top:-1.5rem;
//             left:0;
//         }
//         &:not(:focus)[value='']{
//             & ~.floating-label{
//                 top:1rem;
        
//             }
//         }
  
     
       
//     }

//     input.ant-input[type='password']:invalid{
//         &:focus{
//             & ~.floating-label{
//                 top:-1.5rem;
//                 left:0;
//             }
//         }
//         & ~.floating-label{
//             top:-1.5rem;
//             left:0;
//         }
//         &:not(:focus)[value='']{
//             & ~.floating-label{
//                 top:1rem;
        
//             }
//         }
  
     
       
//     }
//     // input.ant-input:not(:invalid){
//     //     &[value='']{
//     //         & ~.floating-label{
//     //             top:1rem;
       
//     //                // left:0;
//     //            }
//     //     }
       
//     // }

//     // input.ant-input:not([type='password']){
//     //     &[value='']{
//     //         & ~.floating-label{
//     //             top:1rem;
//     //             left:0;
//     //         }
//     //     }
//     // }
//     // input.ant-input[value='']{
//     //     &:focus{
//     //         & ~.floating-label{
//     //             top:-1.5rem;
//     //             left:0;
//     //         }
//     //     }
//     //     & ~.floating-label{
//     //         top:-1.5rem;
//     //         // left:0;
//     //     }
       
//     // }
//     // input.ant-input[value='']{
//     //     & ~.floating-label{
//     //         top:1rem;
//     //     }
//     // }

// 	// input.ant-input{
       
//     //     &:focus{    
//     //             &~.floating-label{
//     //                 z-index: 99;
//     //                 top:-1.5rem;
//     //                 // left:0;
//     //             }
            
            
//     //         }

        
            
     
//     //     }
       
// }

