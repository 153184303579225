@import "src/styles/_variables.scss";

.ant-modal.modal-changed label.floating-label-password {
    z-index: 9;
    font-size: 0.9rem;
    color: #677781;
    position: relative;
    pointer-events: none;
    left: 0;
    margin-top: -5px;
    transition: all 0.1s ease;

    &.error {
        color: $danger-primary;
    }
}

div.input-group {
    margin-top: 0;
}